import React from 'react';
import '../CSS/styles.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const imagesWithMessages = [
  { folder: 'Nyla', image: 'nyla-1.jpg' },
  { folder: 'Joyce', image: 'joyce-1.jpg' },
  {
    folder: 'Grace',
    image: 'grace-1.jpg',
  },
  { folder: 'Emmanuel', image: 'emmanuel-1.jpg' },
  { folder: 'Ola', image: 'ola-1.jpg' },
  { folder: 'Naïmma', image: 'naïmma-1.jpg' },
  { folder: 'Marie-Victoire', image: 'marie-victoire-1.jpg' },
  { folder: 'Portraits', image: 'elodie.jpg' },
  { folder: 'Annie', image: 'annie-1.jpg' },
  { folder: 'Alex', image: 'alex-1.jpg' },
  { folder: 'Kevine', image: 'kevine-1.jpg' },
  { folder: 'Zara', image: 'zara-1.jpg' },
];

const Portrait = () => {
  return (
    <div className='container mt-4'>
      <div className='row mx-auto'>
        {imagesWithMessages.map((item, index) => (
          <div key={index} className='col-lg-3 px-1 mb-3'>
            <Link
              to={{
                pathname: `/gallery/${index}`,
                state: { image: item.image, folder: item.folder },
              }}>
              <div className='image-container'>
                <img
                  src={
                    require(`../Images/${item.folder}/${item.image}`).default
                  }
                  className='img-fluid'
                  alt={`Image ${index + 1}`}
                  loading='lazy'
                  style={{ cursor: 'pointer' }}
                />
                <div className='overlay'>
                  <div className='overlay-text'>{item.folder}</div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portrait;
