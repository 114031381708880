import React from 'react';
import '../CSS/styles.css';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';

// const imagesWithMessages = [
//   { image: 'PT-1.jpg', message: 'Naïmma' },
//   { image: 'PT-2.jpg', message: 'Marie-Victoire' },
//   { image: 'PT-11.jpg', message: 'Street Photography' },
//   { image: 'PT-3.jpg', message: 'Elodie' },
//   { image: 'PT-5.jpg', message: 'Ola' },
//   { image: 'PT-12.jpg', message: 'Joyce' },
//   { image: 'PT-4.jpg', message: 'Grace' },
//   { image: 'PT-6.jpg', message: 'Emmanuella' },
//   { image: 'PT-7.jpg', message: 'Annie' },
//   { image: 'PT-10.jpg', message: 'Alex' },
//   { image: 'PT-9.jpg', message: 'Kevin' },
//   { image: 'PT-8.jpg', message: 'Zara' },
// ];

const DetailView = () => {
  const location = useLocation();
  const { index, folder } = location.state;

  const getImageArray = () => {
    if (folder == 'Nyla') {
      return [
        { image: 'nyla-1.jpg' },
        { image: 'nyla-2.jpg' },
        { image: 'nyla-3.jpg' },
        { image: 'nyla-4.jpg' },
        { image: 'nyla-5.jpg' },
        { image: 'nyla-6.jpg' },
      ];
    } else if (folder == 'Joyce') {
      return [
        { image: 'joyce-1.jpg' },
        { image: 'joyce-3.jpg' },
        { image: 'joyce-4.jpg' },
        { image: 'joyce-2.jpg' },
        { image: 'joyce-5.jpg' },
        { image: 'joyce-6.jpg' },
        { image: 'joyce-7.jpg' },
        { image: 'joyce-8.jpg' },
      ];
    } else if (folder == 'Grace') {
      return [
        { image: 'grace-1.jpg' },
        { image: 'grace-2.jpg' },
        { image: 'grace-3.jpg' },
        { image: 'grace-4.jpg' },
      ];
    } else if (folder == 'Emmanuel') {
      return [
        { image: 'emmanuel-1.jpg' },
        { image: 'emmanuel-3.jpg' },
        { image: 'emmanuel-4.jpg' },
        { image: 'emmanuel-2.jpg' },
      ];
    } else if (folder == 'Ola') {
      return [
        { image: 'ola-1.jpg' },
        { image: 'ola-3.jpg' },
        { image: 'ola-2.jpg' },
        { image: 'ola-4.jpg' },
      ];
    } else if (folder == 'Naïmma') {
      return [
        { image: 'naïmma-1.jpg' },
        { image: 'naïmma-3.jpg' },
        { image: 'naïmma-4.jpg' },
        { image: 'naïmma-2.jpg' },
      ];
    } else if (folder == 'Marie-Victoire') {
      return [
        { image: 'marie-victoire-1.jpg' },
        { image: 'marie-victoire-2.jpg' },
        { image: 'marie-victoire-3.jpg' },
        { image: 'marie-victoire-4.jpg' },
      ];
    } else if (folder == 'Annie') {
      return [{ image: 'annie-1.jpg' }, { image: 'annie-2.jpg' }];
    } else if (folder == 'Alex') {
      return [
        { image: 'alex-1.jpg' },
        { image: 'alex-2.jpg' },
        { image: 'alex-3.jpg' },
        { image: 'alex-4.jpg' },
      ];
    } else if (folder == 'Kevine') {
      return [{ image: 'kevine-1.jpg' }, { image: 'kevine-4.jpg' }];
    } else if (folder == 'Zara') {
      return [{ image: 'zara-1.jpg' }, { image: 'zara-3.jpg' }];
    } else {
      return [{ image: 'elodie.jpg' }, { image: 'woman-dancing.jpg' }];
    }
  };

  const images = getImageArray();

  return (
    <div className='container'>
      {/* <div className='row mx-auto'>
        <h1 className='headerTitle'>{folder}</h1>
      </div> */}
      <div className='row mx-auto mt-4'>
        {images.map((item, index) => (
          <div key={index} className='col-lg-6 px-1 mb-3'>
            <div className='image-container'>
              <img
                src={require(`../Images/${folder}/${item.image}`).default}
                className='img-fluid'
                alt={`Image ${index + 1}`}
                loading='lazy'
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailView;
